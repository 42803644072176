import { GridComponent, ColumnDirective, ColumnsDirective, Toolbar, ExcelExport, Inject, Page, Resize, Sort, Filter } from "@syncfusion/ej2-react-grids";
import { useMemo, useRef } from "react";
import { jobCycleSelector } from "../redux/job_cycle_slice";
import { shallowEqual, useSelector } from "react-redux";
import useIsMobile from "../../../utils/use_is_mobile";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import TableToolbar from "./TableToolbar";

export const mapJobCycle = rawJobCycles => rawJobCycles.map(val => {
    const jobCycle = { ...val };
    if (jobCycle?.OnSiteTimestamp && !jobCycle?.UnloadTimestamp) {
        jobCycle.UnloadTimestamp = jobCycle.OnSiteTimestamp;
    }
    else if (jobCycle?.UnloadTimestamp && !jobCycle?.OnSiteTimestamp) {
        jobCycle.OnSiteTimestamp = jobCycle.UnloadTimestamp;
    }

    if (jobCycle?.UnloadTimestamp && !jobCycle?.ToPlantTimestamp) {
        jobCycle.ToPlantTimestamp = jobCycle.UnloadTimestamp;
    }

    jobCycle.LoadingDuration = jobCycle?.LoadingDuration ?? 0;
    // if (jobCycle?.LoadTimestamp && jobCycle?.ToSiteTimestamp) {
    //     jobCycle.LoadingDuration = moment(moment(jobCycle.ToSiteTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.LoadTimestamp).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm'), 'minutes');
    // }

    jobCycle.ToSiteDuration = jobCycle?.ToSiteDuration ?? 0;
    if (jobCycle?.ToSiteTimestamp && jobCycle?.OnSiteTimestamp) {
        const toSiteDuration = moment(moment(jobCycle.OnSiteTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.ToSiteTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
        if (toSiteDuration >= 0) {
            jobCycle.ToSiteDuration = toSiteDuration;
        }
        else {
            jobCycle.ToSiteDuration = moment(moment(jobCycle.ToSiteTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.OnSiteTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
        }
    }

    jobCycle.WaitingDuration = jobCycle?.WaitingDuration ? jobCycle.WaitingDuration : 0;
    // if (jobCycle?.OnSiteTimestamp && jobCycle?.UnloadTimestamp && jobCycle.WaitingDuration === 0) {
    //     jobCycle.WaitingDuration = moment(moment(jobCycle.UnloadTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.OnSiteTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
    // }

    jobCycle.UnloadDuration = jobCycle?.UnloadDuration ? jobCycle.UnloadDuration : 0;
    if (jobCycle?.UnloadTimestamp && jobCycle?.ToPlantTimestamp && jobCycle.UnloadDuration === 0) {
        jobCycle.UnloadDuration = moment(moment(jobCycle.ToPlantTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.UnloadTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
    }

    jobCycle.OnSiteDuration = jobCycle?.OnSiteDuration ?? 0;
    // if (jobCycle?.UnloadTimestamp && jobCycle?.OnPlantTimestamp) {
    //     jobCycle.OnSiteDuration = moment(moment(jobCycle.OnPlantTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.UnloadTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
    // }

    jobCycle.ToPlantDuration = jobCycle?.ToPlantDuration ?? 0;
    // if (jobCycle?.ToPlantTimestamp && jobCycle?.OnPlantTimestamp) {
    //     jobCycle.ToPlantDuration = moment(moment(jobCycle.OnPlantTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.ToPlantTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
    // }

    jobCycle.CycleTime = jobCycle?.CycleTime ?? 0;
    // if (jobCycle?.ToSiteTimestamp && jobCycle?.OnPlantTimestamp) {
    //     jobCycle.CycleTime = moment(moment(jobCycle.OnPlantTimestamp).format('YYYY-MM-DD HH:mm')).diff(moment(moment(jobCycle.ToSiteTimestamp).format('YYYY-MM-DD HH:mm')), 'minutes');
    // }

    jobCycle.ToSiteDistance = jobCycle?.ToSiteDistance ?? 0;
    jobCycle.ToPlantDistance = jobCycle?.ToPlantDistance ?? 0;
    jobCycle.CycleDistance = jobCycle?.CycleDistance ?? 0;
    jobCycle.JobRemarks = jobCycle?.JobRemarks ?? '';
    jobCycle.ProjectDetails = `${jobCycle.ShipToCode} - ${jobCycle.ShipToName}`;

    return jobCycle;
});

export function JobCycleTable() {
    const { rawJobCycles } = useSelector(jobCycleSelector(['rawJobCycles']), shallowEqual);
    const dataSource = useMemo(() => mapJobCycle(rawJobCycles), [rawJobCycles]);
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();

    const gridRef = useRef();

    useEffect(() => {
        if (gridRef.current) gridRef.current.refreshColumns();
        // eslint-disable-next-line
    }, [t]);

    const projectDetailsTemplate = args => (
        <div>
            <span style={{ fontWeight: 'bold' }}>{args.ShipToCode}</span> <br />
            {args.ShipToName}
        </div>
    );

    const handleSearchOnChange = value => {
        if (gridRef.current) gridRef.current.search(value);
    }

    return (
        <>
            <TableToolbar onSearch={handleSearchOnChange}/>
            <GridComponent
                ref={gridRef}
                dataSource={dataSource}
                // rowDataBound={rowDataBound}
                dataBound={() => {
                    if (gridRef.current) gridRef.current.height = gridRef.current.getCurrentViewRecords().length > 15 ? 700 : 'auto';
                }}
                enableAdaptiveUI={isMobile}
                allowExcelExport={false}
                allowPaging={true}
                allowSorting={true}
                allowResizing={true}
                allowFiltering={true}
                gridLines="Both"
                sortSettings={{ columns: [{ field: 'LoadTimestamp', direction: 'Descending' }] }}
                pageSettings={{ pageSize: '500', pageSizes: ['All', '500'] }}
                filterSettings={{type: 'Excel'}}
                allowEditing={false}
                allowTextWrap={false}
            // selectionSettings={{
            //     checkboxOnly: true,
            // }}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective type='checkbox' className='bg-blue' allowResizing={false} width="65" textAlign="left" /> */}
                    <ColumnDirective field='Division' minWidth={50} width={130} headerText={t('jobCycle.columnDivision')} />
                    <ColumnDirective field='Group' minWidth={50} width={130} headerText={t('jobCycle.columnGroup')} />
                    <ColumnDirective field='Vehicle' minWidth={50} width={130} headerText={t('jobCycle.columnVehicle')} />
                    <ColumnDirective field='DriverName' minWidth={50} width={130} headerText={t('jobCycle.columnDriver')} />
                    <ColumnDirective field='SoldToName' minWidth={50} width={180} headerText={t('jobCycle.columnSoltToName')} />
                    <ColumnDirective field='OrderNum' minWidth={50} width={160} headerText={t('jobCycle.columnOrderNum')} />
                    <ColumnDirective field='TicketNum' minWidth={50} width={180} headerText={t('jobCycle.columnTicketNum')} />
                    <ColumnDirective field='FromPlant' minWidth={50} width={150} headerText={t('jobCycle.columnPlantSite')} />
                    <ColumnDirective field="ProjectDetails" minWidth={50} width={250} headerText={t('jobCycle.columnProjectDetails')} template={projectDetailsTemplate} />
                    <ColumnDirective field='Material' minWidth={50} width={140} headerText={t('jobCycle.columnMaterial')} />
                    <ColumnDirective field='Quantity' minWidth={50} width={140} headerText={t('jobCycle.columnQuantity')} />
                    <ColumnDirective field='Cumulative' minWidth={50} width={220} headerText={t('jobCycle.columnCumulative')} />
                    <ColumnDirective field='UOM' minWidth={50} width={110} headerText={t('jobCycle.columnUOM')} />
                    <ColumnDirective field='LoadTimestamp' minWidth={50} width={180} headerText={t('jobCycle.columnLoadTimestamp')} template={args => args.LoadTimestamp ? moment(args.LoadTimestamp).format('DD-MMM-yyyy HH:mm') : '--'} />
                    <ColumnDirective field='ToSiteTimestamp' minWidth={50} width={160} headerText={t('jobCycle.columnLeftPlant')} template={args => args.ToSiteTimestamp ? moment(args.ToSiteTimestamp).format('DD-MMM-yyyy HH:mm') : '--'} />
                    <ColumnDirective field='OnSiteTimestamp' minWidth={50} width={170} headerText={t('jobCycle.columnArriveAtSite')} template={args => args.OnSiteTimestamp ? moment(args.OnSiteTimestamp).format('DD-MMM-yyyy HH:mm') : '--'} />
                    <ColumnDirective field='UnloadTimestamp' minWidth={50} width={160} headerText={t('jobCycle.columnUnload')} template={args => args.UnloadTimestamp ? moment(args.UnloadTimestamp).format('DD-MMM-yyyy HH:mm') : '--'} />
                    <ColumnDirective field='ToPlantTimestamp' minWidth={50} width={160} headerText={t('jobCycle.columnLeftJobSite')} template={args => args.ToPlantTimestamp ? moment(args.ToPlantTimestamp).format('DD-MMM-yyyy HH:mm') : '--'} />
                    <ColumnDirective field='OnPlantTimestamp' minWidth={50} width={170} headerText={t('jobCycle.columnArrivedPlant')} template={args => args.OnPlantTimestamp ? moment(args.OnPlantTimestamp).format('DD-MMM-yyyy HH:mm') : '--'} />
                    <ColumnDirective field='LoadingDuration' minWidth={50} width={160} headerText={t('jobCycle.columnLoadingDuration')} defaultValue="0" />
                    <ColumnDirective field='ToSiteDuration' minWidth={50} width={220} headerText={t('jobCycle.columnToSiteDuration')} defaultValue="0" />
                    <ColumnDirective field='WaitingDuration' minWidth={50} width={200} headerText={t('jobCycle.columnWaitingTimeDuration')} />
                    <ColumnDirective field='UnloadDuration' minWidth={50} width={180} headerText={t('jobCycle.columnUnloadingDuration')} />
                    <ColumnDirective field='OnSiteDuration' minWidth={50} width={220} headerText={t('jobCycle.columnOnSiteDuration')} />
                    <ColumnDirective field='ToPlantDuration' minWidth={50} width={230} headerText={t('jobCycle.columnToPlantDuration')} />
                    <ColumnDirective field='CycleTime' minWidth={50} width={180} headerText={t('jobCycle.columnCycleTime')} />
                    <ColumnDirective field='ToSiteDistance' minWidth={50} width={220} headerText={t('jobCycle.columnToSiteDistance')} />
                    <ColumnDirective field='ToPlantDistance' minWidth={50} width={220} headerText={t('jobCycle.columnToPlantDistance')} />
                    <ColumnDirective field='CycleDistance' minWidth={50} width={210} headerText={t('jobCycle.columnCycleDistance')} />
                    <ColumnDirective field='JobRemarks' minWidth={50} width={180} headerText={t('jobCycle.columnJobRemakrs')} />
                </ColumnsDirective>
                <Inject services={[Toolbar, ExcelExport, Page, Resize, Sort, Filter]} />
            </GridComponent>
        </>
    );
}